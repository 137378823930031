import React, { useContext, useEffect, useState } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation, redirect } from 'react-router-dom';
// CUSTOM HOOKS
import { useHelpers } from '../hooks/useHelpers';
// GOOGLE MAPS
import { Wrapper, Status } from '@googlemaps/react-wrapper';
// AXIOS
import axios from 'axios';
// PARTIALS
import Map from '../partials/map';
// REACT HOOK FORM
import { useForm, useFieldArray } from "react-hook-form";
// REACT SELECT
import Select from 'react-select';
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
// REACT FILE UPLOADER
import { FileUploader } from "react-drag-drop-files";
// REACT SPINNER
import { Watch } from 'react-loader-spinner';
// REACT SWEETALERT
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// DYNAMIC ICON
import DynamicIcon from '../partials/DynamicIcon';
import { FaLaptopHouse } from 'react-icons/fa';

function EvaluationsVal() {
  // AUTH, STATE, NAVIGATION
  const { authobj } = useContext(AuthContext);
  const { state } = useLocation();
  let navigate = useNavigate();
  // CUSTOM HOOKS
  const { handleSuspension, geocode_point } = useHelpers();
  // FORM
  const { register, handleSubmit, getValues, setValue, reset, formState: { errors } } = useForm();
    // SWEETALERT
  const MySwal = withReactContent(Swal);
  // DATA
  const [evaluation, setEvaluation] = useState(null);
  const [fotoCategories, setFotoCategories] = useState(null);
  const [step, setStep] = useState(0);
  const [lastStep, setLastStep] = useState(false);
  const [diffTypes, setDiffTypes] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [diffResBtnArray, setDiffResBtnArray] = useState({});
  // DATA - POPUP
  const [popupOptions, setPopupOptions] = useState([]);
  // DATA - MAPPA
  const [initCoords, setInitCoords] = useState({ lat: 42.850127, lng: 13.6246959 });  
  const [coords, setCoords] = useState({ lat: 42.850127, lng: 13.6246959 });  
  const [center, setCenter] = useState({ lat: 42.850127, lng: 13.6246959 });
  const [zoom, setZoom] = useState(18);
  const [mapKey, setMapKey] = useState(0);
  const [mapOk, setMapOk] = useState(false);
  // FILE UPLOADER
  const [file, setFile] = useState(null);
  const [inspectionFotoFiles, setInspectionFotoFiles] = useState([]);
  const [inspectionDiffFiles, setInspectionDiffFiles] = useState([]);
  const [inspectionFotoCats, setInspectionFotoCats] = useState([]);
  // SPINNER
  const [wait, setWait] = useState(false);
  const [waitPercent, setWaitPercent] = useState(null);

  useEffect(() => {
    setWait(true);

    // Se sto modificando una pratica esistente, carico le informazioni relative
    if (state && state.evaluation_id) {
      axios.post(window.SERVER_URL+'evaluations/get', { id: state.evaluation_id }, { headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token
      }}).then(res => {
        console.log(res.data);
        let tmp_eval = res.data.evaluation;
        setEvaluation(res.data.evaluation);

        // Carico le province
        axios.post(window.SERVER_URL+'provinces/list', { }, { headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token
        }}).then(resp => {
          setProvinces(resp.data.provinces);

          // Carico le categorie di foto
          axios.post(window.SERVER_URL+'inspection_image_categories/list', {}, {
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Authorization': 'Bearer '+authobj.token
            }
          }).then(resc => {
            console.log(resc.data);
            setFotoCategories(resc.data.image_categories);

            // Carico le foto del sopralluogo
            axios.post(window.SERVER_URL+'inspections/get_files', { evaluation_id: state.evaluation_id, file_type: 'foto' }, {
              headers: {
                'Access-Control-Allow-Origin': '*',
                'Authorization': 'Bearer '+authobj.token
              }
            }).then(resf => {
              console.log(resf.data);
              setInspectionFotoFiles(resf.data.files);
              setInspectionFotoCats(resf.data.cats);

              // Carico le difformità del sopralluogo
              axios.post(window.SERVER_URL+'inspections/get_files', { evaluation_id: state.evaluation_id, file_type: 'difformita' }, {
                headers: {
                  'Access-Control-Allow-Origin': '*',
                  'Authorization': 'Bearer '+authobj.token
                }
              }).then(resf => {
                console.log(resf.data);
                setInspectionDiffFiles(resf.data.files);

                // Carico le opzioni per la select del popup delle foto
                axios.post(window.SERVER_URL+'popup_motifs/list', { sezione: 'inspection_step_3', evaluation_id: state.evaluation_id }, {
                  headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': 'Bearer '+authobj.token
                  }
                }).then(reso => {
                  console.log(reso.data);
                  setPopupOptions(reso.data.motifs);

                  // Carico le tipologie di difformità
                  axios.post(window.SERVER_URL+'inspection_differences/list', {}, {
                    headers: {
                      'Access-Control-Allow-Origin': '*',
                      'Authorization': 'Bearer '+authobj.token
                    }
                  }).then(resd => {
                    console.log(resd.data);
                    setDiffTypes(resd.data.differences);

                    let tmp_coords = geocode_point(tmp_eval.indirizzo+' '+tmp_eval.civico+' '+tmp_eval.cap+' '+tmp_eval.comune);
                    tmp_coords.then(response => {
                      console.log(tmp_coords);
                      let tmp_lat = response.data.results && response.data.results.length ? response.data.results[0].geometry.location.lat : '';
                      let tmp_lng = response.data.results && response.data.results.length ? response.data.results[0].geometry.location.lng : '';
                      // CONTROLLO SE CI SONO LE COORDINATE SALVATE NELLA PERIZIA - SE SI, USO QUELLE ANZICHE QUELLE DEL GEOCODE
                      if (res.data.evaluation.lat && res.data.evaluation.lng) {
                        tmp_lat = res.data.evaluation.lat;
                        tmp_lng = res.data.evaluation.lng;
                      }
                      setInitCoords({ lat: tmp_lat, lng: tmp_lng });
                      // VEDO SE CI SONO COORDINATE AGGIORNATE SALVATE PER IL SOPRALLUOGO ALTRIMENTI USO QUELLE CALCOLATE
                      tmp_lat = parseFloat(res.data.evaluation && res.data.evaluation.inspection && res.data.evaluation.inspection.lat ? res.data.evaluation.inspection.lat : tmp_lat);
                      tmp_lng = parseFloat(res.data.evaluation && res.data.evaluation.inspection && res.data.evaluation.inspection.lng ? res.data.evaluation.inspection.lng : tmp_lng);
                      setCoords({ lat: tmp_lat, lng: tmp_lng });
                      setCenter({ lat: tmp_lat, lng: tmp_lng });
                      setMapOk(true);

                      setWait(false);
                    });
                  });
                });
              });
            });
          });
        });
      });
    } else {
      setWait(false);
    }
  }, [reset]);

  const goToStep = async (num) => {
    setWait(true);
    console.log("STO ALLO STEP "+step);
    console.log("VADO ALLO STEP "+num);
    // Validazione dei dati a seconda dello step
    let valid = true;
    const values = getValues();
    switch (step) {
      // VALIDAZIONE STEP 1
      case 0:
        setWait(false);
      break;
      // VALIDAZIONE STEP 1
      case 1:
        setWait(false);
      break;
      // VALIDAZIONE STEP 2
      case 2:
        setWait(false);
      break;
      // VALIDAZIONE STEP 3
      case 3:
        setWait(false);
      break;
      // VALIDAZIONE STEP 4
      case 4:
        setWait(false);
      break;
    }
    // Mi muovo verso lo step passato come parametro
    if (num == 999) {
      setLastStep(true);
      setWait(false);

      console.log(">>> STEP "+step);
      console.log(">>> NUM "+num);

      handleSubmit(onSubmit)();
      showEndingPopup();

    } else {
      setLastStep(false);
    }
    if (valid && num != 999) {
      switch (num) {
      }
      setStep(num);
    }
  }

  const showEndingPopup = () => {
    Swal.fire({
      html: `<img src="${process.env.PUBLIC_URL}/gfx/Raggruppa138.png"><br><b>Conferma evasione pratica</b><br>Vuoi confermare l'evasione<br>della pratica ID ${evaluation.id}?`,
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: "Sì, lo confermo",
      cancelButtonText: "No, non confermo",
      confirmButtonColor: "#12275d",
      cancelButtonColor: "#12275d"
    }).then((result) => {
      if (result.isConfirmed) {
        setValue('completato', 'qual-val');
        handleSubmit(onSubmit)();
        Swal.fire({
          html: `<img src="${process.env.PUBLIC_URL}/gfx/Raggruppa138.png"><br><b>Ottimo lavoro!</b><br>La tua azione è andata a buon fine`,
          showDenyButton: false,
          showCancelButton: false,
          confirmButtonText: "Torna alla dashboard",
          // cancelButtonText: "Torna alla dashboard",
          confirmButtonColor: "#12275d",
          cancelButtonColor: "#12275d"
        }).then((result) => {
          if (result.isConfirmed) {
            // torna alla dashboard
            navigate('/dashboard');
          }
        });
      }
    });
  }

  const handleResolution = (ass_id) => {
    axios.post(window.SERVER_URL+'inspection_differences/get_resolution', { id: ass_id }, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      Swal.fire({
        html: `<img src="${process.env.PUBLIC_URL}/gfx/feedback_positivo.png"><br>
                <b>Risoluzione difformità</b><br><br>
                <label style="font-weight: 600;">Descrizione difformità</label>
                <p>${res.data.difformita_note ? res.data.difformita_note : ''}</p>
                <label style="font-weight: 600;">Indicazioni risoluzione</label>
                <p>${res.data.difformita_indicazioni ? res.data.difformita_indicazioni : ''}</p>
                <label style="font-weight: 600;">Costi per la risoluzione (€)</label>
                <p>${res.data.difformita_importo ? res.data.difformita_importo : ''} €</p>
                <br><br>`,
        showDenyButton: false,
        // showCancelButton: true,
        // confirmButtonText: "Salva",
        // cancelButtonText: "Annulla",
        confirmButtonColor: "#12275d",
        cancelButtonColor: "#12275d",
        width: '800px'
      });
    });
  }
  
  // Submit della form
  const onSubmit = (form_data) => {
    form_data.evaluation_id = (state && state.evaluation_id ? state.evaluation_id : (evaluation ? evaluation.id : null));
    axios.post(window.SERVER_URL+'inspections/save', form_data, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token,
      }
    }).then(res => {
      if (res.data.success) {
        setWait(false);        
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Errore',
          text: "Non è stato possibile salvare la validazione"
        });
      }
    }).catch(function(error) {
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'Errore',
        text: "Non è stato possibile salvare la validazione"
      });
    });
  }

  const render = (status) => {
    if (status === Status.SUCCESS) {
      return <Map key={mapKey} center={center} zoom={zoom} width={'100%'} height={'600px'} checks={[coords]} noControls noDrag />;
    }
  }

  const viewGoogleMaps = () => {
    if (evaluation && evaluation.inspection) {
      window.open('https://www.google.com/maps/@'+evaluation.inspection.lat+','+evaluation.inspection.lng+',12?q='+evaluation.inspection.lat+','+evaluation.inspection.lng);
    }
  }

  return (
    <>
      { wait ? 
        <div id="wait">
          { waitPercent !== null ?
            <div className="percent">{waitPercent}%</div>
          : '' }
          <Watch height="80" width="80" radius="48" color="#44B2EB" ariaLabel="watch-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />
        </div>
      : '' }
      <Container className="content">
        <Row className="page_title">
          <Col>
            <h1 style={{'textAlign':'left'}}>
              Validazione pratica: 
              <span className='bordered_info full'>{ evaluation ? evaluation.id : '' }</span>
              <span className='bordered_info grey'>{ evaluation && evaluation.evaluation_type ? evaluation.evaluation_type.descrizione : '' }</span>
            </h1>
          </Col>
        </Row>
        <Row>
          {/* <Col className="custom_form_wrapper"> */}
          <Col lg={9} className="custom_form_wrapper">
            <form className="custom_form" onSubmit={handleSubmit(onSubmit)}>
              <input type="hidden" name="completato" {...register("completato")} />
              {/* STEP 0 - ANTEPRIMA DETTAGLIO PRATICA */}
              <fieldset className={step == 0 ? 'wizard_step_content reg visible' : 'wizard_step_content reg'}>
                <Row>
                  <Col>
                    <h2>Dettaglio pratica</h2>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label className='step_label'>Dati sopralluogo</label>
                  </Col>
                </Row>
                <div className='inner_wrapper'>
                  <Row>
                    <Col>
                      <label className='step_label'>Indirizzo</label>
                      <Row>
                        <Col>
                          <label>Indirizzo</label>
                          <p>{ evaluation ? evaluation.indirizzo : '-' }</p>
                        </Col>
                        <Col>
                          <label>Civico</label>
                          <p>{ evaluation ? evaluation.civico : '-' }</p>
                        </Col>
                        <Col>
                          <label>Frazione / Località</label>
                          <p>{ evaluation ? evaluation.localita : '-' }</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <label>Città</label>
                          <p>{ evaluation && evaluation.town ? evaluation.town.nome : '-' }</p>
                        </Col>
                        <Col>
                          <label>Provincia</label>
                          <p>{ evaluation && evaluation.province ? evaluation.province.nome : '-' }</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <label>CAP</label>
                          <p>{ evaluation ? evaluation.cap : '-' }</p>
                        </Col>
                        <Col>
                          <label>Stato</label>
                          <p>{ evaluation ? evaluation.nazione : '-' }</p>
                        </Col>
                      </Row>
                      <label className='step_label' style={{'marginTop':'20px'}}>Coordinate inserite</label>
                      <Row>
                        <Col>
                          <label>Latitudine</label>
                          <p>{ evaluation && evaluation.inspection ? evaluation.inspection.lat : '-' }</p>
                        </Col>
                        <Col>
                          <label>Longitudine</label>
                          <p>{ evaluation && evaluation.inspection ? evaluation.inspection.lng : '-' }</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Button onClick={() => viewGoogleMaps()} style={{'marginTop':'20px','float':'left'}}>Vedi su Google Maps</Button>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      { mapOk ?
                        <Wrapper apiKey={window.GMAPS_APIKEY} render={render}></Wrapper>
                      : '' }
                    </Col>
                  </Row>
                </div>
                <Row>
                  <Col>
                    <label className='step_label'>Elenco file forniti</label>
                  </Col>
                </Row>
                <Row>
                  <Col className='tab_wrapper'>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>Nome</th>
                          <th style={{'width':'120px'}}>Azioni</th>
                        </tr>
                      </thead>
                      <tbody>
                        { evaluation && evaluation.files && evaluation.files.length ? evaluation.files.map((f) => 
                          <tr>
                            <td>{ f.nome }</td>
                            <td className="actions">
                              <Button title="Scarica file" onClick={() => { window.open(f.link); }}><DynamicIcon iconName="FaDownload" /></Button>
                            </td>
                          </tr>
                        ) : '' }
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label className='step_label'>Elenco dati catastali beni della pratica</label>
                  </Col>
                </Row>
                <div className='inner_wrapper'>
                  { evaluation && evaluation.num_asset.F > 0 ?
                    <>
                      <label><b>Fabbricati</b></label>
                      <Row>
                        <Col className='tab_wrapper'>
                          <Table responsive>
                          <thead>
                            <tr>
                              {/* <th style={{'width':'60px'}}>Tipo</th> */}
                              <th style={{'width':'350px'}}>Comune catastale</th>
                              <th style={{'width':'100px'}}>Sezione</th>
                              <th style={{'width':'100px'}}>Foglio</th>
                              <th style={{'width':'100px'}}>Particella</th>
                              <th>Subalterno</th>
                              <th>Categoria</th>
                            </tr>
                          </thead>
                          <tbody>
                            { evaluation && evaluation.assets && evaluation.assets.length ? evaluation.assets.map((asset) =>
                                asset.parcel_type.sigla == 'F' ? (
                                  <tr key={ asset.id }>
                                    {/* <td>{ asset.parcel_type ? asset.parcel_type.sigla : '-' }</td> */}
                                    <td style={{'width':'350px'}}>{ asset.comune_catastale ? asset.comune_catastale : '-' }</td>
                                    <td style={{'width':'100px'}}>{ asset.sezione ? asset.sezione : '-' }</td>
                                    <td style={{'width':'100px'}}>{ asset.foglio ? asset.foglio : '-' }</td>
                                    <td style={{'width':'100px'}}>{ asset.mappale ? asset.mappale :'-' }</td>
                                    <td>{ asset.subalterno ? asset.subalterno : '-' }</td>
                                    <td>{ asset.registry_category ? asset.registry_category.descrizione : '-' }</td>
                                  </tr>
                                ) : null
                            ) : '' }
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                    </>
                  : '' }
                  { evaluation && evaluation.num_asset.T > 0 ?
                    <>
                      <label><b>Terreni</b></label>
                      <Row>
                        <Col className='tab_wrapper'>
                          <Table responsive>
                          <thead>
                            <tr>
                              {/* <th style={{'width':'60px'}}>Tipo</th> */}
                              <th style={{'width':'350px'}}>Comune catastale</th>
                              <th style={{'width':'100px'}}>Sezione</th>
                              <th style={{'width':'100px'}}>Foglio</th>
                              <th style={{'width':'100px'}}>Particella</th>
                              <th></th>
                              {/* <th>Porzione</th> */}
                              {/* <th>Qualità</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            { evaluation && evaluation.assets && evaluation.assets.length ? evaluation.assets.map((asset) =>
                                asset.parcel_type.sigla == 'T' ? (
                                  <tr key={ asset.id }>
                                    {/* <td>{ asset.parcel_type ? asset.parcel_type.sigla : '-' }</td> */}
                                    <td style={{'width':'350px'}}>{ asset.comune_catastale }</td>
                                    <td style={{'width':'100px'}}>{ asset.sezione ? asset.sezione : '-' }</td>
                                    <td style={{'width':'100px'}}>{ asset.foglio ? asset.foglio : '-' }</td>
                                    <td style={{'width':'100px'}}>{ asset.mappale ? asset.mappale : '-' }</td>
                                    <td></td>
                                    {/* <td>{ asset.subalterno }</td> */}
                                    {/* <td>{ asset.registry_category ? asset.registry_category.descrizione : '-' }</td> */}
                                  </tr>
                                ) : null
                            ) : '' }
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                    </>
                  : '' }
                </div>
                <Row>
                  <Col>
                    <label className='step_label'>Rilevamenti fotografici</label>
                  </Col>
                </Row>
                <Row>
                  <Col className='tab_wrapper'>
                    { inspectionFotoFiles && inspectionFotoFiles.length ? inspectionFotoFiles.map((f) => 
                      <div className={ f.discarded_at ? 'fotoThumbWrapper discarded' : f.user && f.user.role && f.user.role.slug == 'qual-cont' ? 'fotoThumbWrapper added' : 'fotoThumbWrapper' }>
                        <p>
                          <img src={process.env.PUBLIC_URL+'/gfx/SPF_AssetsIcone-32.png'} style={{'height':'16px'}} />
                          <span className='filename'>{ f.nome }</span>
                        </p>
                        <div className='fotoThumb' style={{'backgroundImage':"url('"+f.link+"')"}}></div>
                        <div className='bottom_bar'>
                          <span style={{'fontSize':'12px','display':'inline-block','fontWeight':'600','marginRight':'10px'}}>{ f.inspection_image_category.descrizione.substr(0, 24)+(f.inspection_image_category.descrizione.length > 24 ? '...' : '') }</span>
                          <a href="#" style={{'float':'right'}} onClick={() => { window.open(f.link); }}><DynamicIcon iconName="FaDownload" /></a>                     
                        </div>
                      </div>
                    ) : '' }
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label className='step_label'>Planimetrie e difformità</label>
                  </Col>
                </Row>
                <Row>
                  <Col className='tab_wrapper'>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>Nome</th>
                          {/* <th>Peso</th>
                          <th>Data caricamento</th> */}
                          <th>Tripletta catastale</th>
                          <th style={{'width':'120px'}}>Azioni</th>
                        </tr>
                      </thead>
                      <tbody>
                        { inspectionDiffFiles && inspectionDiffFiles.length ? inspectionDiffFiles.map((f) => 
                          <tr className={ f.discarded_at ? 'discarded' : f.user && f.user.role && f.user.role.slug == 'qual-cont' ? 'added' : '' }>
                            <td>{ f.nome }</td>
                            {/* <td>{ f.peso }</td>
                            <td>{ f.data_caricamento }</td> */}
                            <td>{ f.asset ? f.asset.foglio+' '+f.asset.mappale+' '+f.asset.subalterno : '-' }</td>
                            <td className="actions">
                              <Button title="Scarica file" onClick={() => { window.open(f.link); }}><DynamicIcon iconName="FaDownload" /></Button>
                            </td>
                          </tr>
                        ) : '' }
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <Row style={{'marginTop':'20px'}}>
                  <Col>
                    <label className='step_label'>Riscontro difformità</label>
                  </Col>
                </Row>
                <div className='inner_wrapper'>
                  { evaluation && evaluation.num_asset.F > 0 ?
                  <>
                    <label><b>Fabbricati</b></label>
                    <Row>
                      <Col className='tab_wrapper'>
                        { evaluation && evaluation.assets && evaluation.assets.length ? 
                          <Table responsive>
                            <thead>
                              <tr>
                                {/* <th style={{'width':'50px'}}>Tipo</th> */}
                                <th style={{'width':'350px'}}>Comune catastale</th>
                                <th style={{'width':'90px'}}>Sezione</th>
                                <th style={{'width':'100px'}}>Foglio</th>
                                <th style={{'width':'100px'}}>Particella</th>
                                <th style={{'width':'60px'}}>Sub</th>
                                <th style={{'width':'60px'}}>Cat</th>
                                <th style={{'width':'100px'}}>Difformità</th>
                                <th style={{'width':'140px'}}>Tipo</th>
                                <th style={{'width':'120px'}}>Descrizione risoluzione</th>
                              </tr>
                            </thead>
                            <tbody>
                              { evaluation.assets.map((ass) => 
                                ass.parcel_type.sigla == 'F' ? (
                                  <tr>
                                    {/* <td>{ ass.parcel_type ? ass.parcel_type.sigla : '-' }</td> */}
                                    <td style={{'width':'350px'}}>{ ass.comune_catastale ? ass.comune_catastale : '-' }</td>
                                    <td style={{'width':'90px'}}>{ ass.sezione ? ass.sezione : '-' }</td>
                                    <td style={{'width':'100px'}}>{ ass.foglio ? ass.foglio : '-' }</td>
                                    <td style={{'width':'100px'}}>{ ass.mappale ? ass.mappale :'-' }</td>
                                    <td style={{'width':'60px'}}>{ ass.subalterno ? ass.subalterno : '-' }</td>
                                    <td style={{'width':'60px'}}>{ ass.registry_category ? ass.registry_category.descrizione : '-' }</td>
                                    <td style={{'width':'100px'}}>{ ass.difformita == '1' ? 'Sì' : (ass.difformita == '0' ? 'No' : '-') }</td>
                                    <td style={{'width':'140px'}}>{ ass.inspection_difference ? ass.inspection_difference.descrizione : '-' }</td>
                                    <td className="actions">
                                      <Button title="Vedi risoluzione" onClick={() => { handleResolution(ass.id) }} className={ !ass.difformita || ass.difformita.toString() !== '1' ? 'disabled' : '' }><DynamicIcon iconName="FaEye" /></Button>
                                    </td>
                                  </tr>
                                ) : null
                              ) }
                            </tbody>
                          </Table>
                        : '' }
                      </Col>
                    </Row>
                  </>
                  : '' }
                  { evaluation && evaluation.num_asset.T > 0 ?
                  <>
                    <label><b>Terreni</b></label>
                    <Row>
                      <Col className='tab_wrapper'>
                        { evaluation && evaluation.assets && evaluation.assets.length ? 
                          <Table responsive>
                            <thead>
                              <tr>
                                {/* <th style={{'width':'50px'}}>Tipo</th> */}
                                <th style={{'width':'350px'}}>Comune catastale</th>
                                <th style={{'width':'100px'}}>Sezione</th>
                                <th style={{'width':'100px'}}>Foglio</th>
                                <th style={{'width':'100px'}}>Particella</th>
                                <th style={{'width':'120px'}}></th>
                                <th style={{'width':'100px'}}>Difformità</th>
                                <th style={{'width':'140px'}}>Tipo</th>
                                <th style={{'width':'120px'}}>Descrizione risoluzione</th>
                              </tr>
                            </thead>
                            <tbody>
                              { evaluation.assets.map((ass) => 
                                ass.parcel_type.sigla == 'T' ? (
                                  <tr>
                                    {/* <td>{ ass.parcel_type ? ass.parcel_type.sigla : '-' }</td> */}
                                    <td style={{'width':'350px'}}>{ ass.comune_catastale }</td>
                                    <td style={{'width':'100px'}}>{ ass.sezione ? ass.sezione : '-' }</td>
                                    <td style={{'width':'100px'}}>{ ass.foglio ? ass.foglio : '-' }</td>
                                    <td style={{'width':'100px'}}>{ ass.mappale ? ass.mappale : '-' }</td>
                                    <td style={{'width':'120px'}}></td>
                                    <td style={{'width':'100px'}}>{ ass.difformita == '1' ? 'Sì' : (ass.difformita == '0' ? 'No' : '-') }</td>
                                    <td style={{'width':'140px'}}>{ ass.inspection_difference ? ass.inspection_difference.descrizione : '-' }</td>
                                    <td className="actions">
                                      <Button title="Vedi risoluzione" onClick={() => { handleResolution(ass.id) }} className={ !ass.difformita || ass.difformita.toString() !== '1' ? 'disabled' : '' }><DynamicIcon iconName="FaEye" /></Button>
                                    </td>
                                  </tr>
                                ) : null
                              ) }
                            </tbody>
                          </Table>
                        : '' }
                      </Col>
                    </Row>
                  </>
                  : '' }
                </div>
                <Row style={{'marginTop':'20px'}}>
                  <Col>
                    <label className='step_label'>Risposte e note al questionario</label>
                  </Col>
                </Row>
                <div className='inner_wrapper'>
                  <Row>
                    <Col>
                      <Row style={{'marginBottom':'10px'}}>
                        <Col>
                          <label>Hai avuto accesso a tutti i beni indicati come quelli da periziare?</label>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          { evaluation && evaluation.inspection && JSON.parse(evaluation.inspection.questionario)[0] == '1' ? 'Sì' : 'No' }
                        </Col>
                      </Row>
                      <Row style={{'marginBottom':'10px','marginTop':'25px'}}>
                        <Col>
                          <label>Hai potuto fare le foto a tutti gli ambienti?</label>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          { evaluation && evaluation.inspection && JSON.parse(evaluation.inspection.questionario)[1] == '1' ? 'Sì' : 'No' }
                        </Col>
                      </Row>
                      <Row style={{'marginBottom':'10px','marginTop':'25px'}}>
                        <Col>
                          <label>Hai potuto prendere le misure reali?</label>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          { evaluation && evaluation.inspection && JSON.parse(evaluation.inspection.questionario)[2] == '1' ? 'Sì' : 'No' }
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <label>Note del tecnico</label>
                      <p>{ evaluation && evaluation.inspection ? evaluation.inspection.note : '-' }</p>
                      <label>Note della qualità - controllo</label>
                      <p>{ evaluation && evaluation.inspection ? evaluation.inspection.note_qualita_1 : '-' }</p>
                    </Col>
                  </Row>
                </div>
              </fieldset>
              {/* STEP 1 - COORDINATE E DATI CATASTALI */}
              <fieldset className={step == 1 ? 'wizard_step_content reg visible' : 'wizard_step_content reg'}>
                <Row>
                  <Col>
                    <h2>Validazione coordinate e dati catastali</h2>
                  </Col>
                </Row>
                <div className='inner_wrapper'>
                  <Row>
                    <Col>
                      <label className='step_label'>Indirizzo</label>
                      <Row>
                        <Col>
                          <label>Indirizzo</label>
                          <p>{ evaluation ? evaluation.indirizzo : '-' }</p>
                        </Col>
                        <Col>
                          <label>Civico</label>
                          <p>{ evaluation ? evaluation.civico : '-' }</p>
                        </Col>
                        <Col>
                          <label>Frazione / Località</label>
                          <p>{ evaluation ? evaluation.localita : '-' }</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <label>Città</label>
                          <p>{ evaluation && evaluation.town ? evaluation.town.nome : '-' }</p>
                        </Col>
                        <Col>
                          <label>Provincia</label>
                          <p>{ evaluation && evaluation.province ? evaluation.province.nome : '-' }</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <label>CAP</label>
                          <p>{ evaluation ? evaluation.cap : '-' }</p>
                        </Col>
                        <Col>
                          <label>Stato</label>
                          <p>{ evaluation ? evaluation.nazione : '-' }</p>
                        </Col>
                      </Row>
                      <label className='step_label' style={{'marginTop':'20px'}}>Coordinate inserite</label>
                      <Row>
                        <Col>
                          <label>Latitudine</label>
                          <p>{ evaluation && evaluation.inspection ? evaluation.inspection.lat : '-' }</p>
                        </Col>
                        <Col>
                          <label>Longitudine</label>
                          <p>{ evaluation && evaluation.inspection ? evaluation.inspection.lng : '-' }</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Button onClick={() => viewGoogleMaps()} style={{'marginTop':'20px','float':'left'}}>Vedi su Google Maps</Button>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      { mapOk ?
                        <Wrapper apiKey={window.GMAPS_APIKEY} render={render}></Wrapper>
                      : '' }
                    </Col>
                  </Row>
                </div>
                <Row>
                  <Col>
                    <label className='step_label'>Foto contesto urbanistico e civico</label>
                  </Col>
                </Row>
                <Row style={{'marginBottom':'0','marginTop':'20px'}}>
                  <Col>
                    { inspectionFotoFiles && inspectionFotoFiles.length ? inspectionFotoFiles.map((f) =>
                      f.inspection_image_category && (f.inspection_image_category.descrizione.toLowerCase().match("urbanistico") || f.inspection_image_category.descrizione.toLowerCase().match("civico")) ?
                        <div className={ f.discarded_at ? 'fotoThumbWrapper discarded' : f.user && f.user.role && f.user.role.slug == 'qual-cont' ? 'fotoThumbWrapper added' : 'fotoThumbWrapper' }>
                          <p>
                            <img src={process.env.PUBLIC_URL+'/gfx/SPF_AssetsIcone-32.png'} style={{'height':'16px'}} />
                            <span className='filename'>{ f.nome }</span>
                          </p>
                          <div className='fotoThumb' style={{'backgroundImage':"url('"+f.link+"')"}}></div>
                          <div className='bottom_bar'>
                            <span style={{'fontSize':'12px','display':'inline-block','fontWeight':'600','marginRight':'10px'}}>{ f.inspection_image_category.descrizione.substr(0, 24)+(f.inspection_image_category.descrizione.length > 24 ? '...' : '') }</span>
                            <a href="#" style={{'float':'right'}} onClick={() => { window.open(f.link); }}><DynamicIcon iconName="FaDownload" /></a>                     
                          </div>
                        </div>
                      : ''
                    ) : '' }
                  </Col>
                </Row>
                <Row style={{'marginTop':'20px'}}>
                  <Col>
                    <label className='step_label'>Elenco file forniti</label>
                  </Col>
                </Row>
                <Row>
                  <Col className='tab_wrapper'>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>Nome</th>
                          <th style={{'width':'120px'}}>Azioni</th>
                        </tr>
                      </thead>
                      <tbody>
                        { evaluation && evaluation.files && evaluation.files.length ? evaluation.files.map((f) => 
                          <tr>
                            <td>{ f.nome }</td>
                            <td className="actions">
                              <Button title="Scarica file" onClick={() => { window.open(f.link); }}><DynamicIcon iconName="FaDownload" /></Button>
                            </td>
                          </tr>
                        ) : '' }
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label className='step_label'>Elenco dati catastali beni della pratica</label>
                  </Col>
                </Row>
                <div className='inner_wrapper'>
                  { evaluation && evaluation.num_asset.F > 0 ?
                    <>
                      <label><b>Fabbricati</b></label>
                      <Row>
                        <Col className='tab_wrapper'>
                          <Table responsive>
                          <thead>
                            <tr>
                              {/* <th style={{'width':'60px'}}>Tipo</th> */}
                              <th style={{'width':'350px'}}>Comune catastale</th>
                              <th style={{'width':'100px'}}>Sezione</th>
                              <th style={{'width':'100px'}}>Foglio</th>
                              <th style={{'width':'100px'}}>Particella</th>
                              <th>Subalterno</th>
                              <th>Categoria</th>
                            </tr>
                          </thead>
                          <tbody>
                            { evaluation && evaluation.assets && evaluation.assets.length ? evaluation.assets.map((asset) =>
                                asset.parcel_type.sigla == 'F' ? (
                                  <tr key={ asset.id }>
                                    {/* <td>{ asset.parcel_type ? asset.parcel_type.sigla : '-' }</td> */}
                                    <td style={{'width':'350px'}}>{ asset.comune_catastale ? asset.comune_catastale : '-' }</td>
                                    <td style={{'width':'100px'}}>{ asset.sezione ? asset.sezione : '-' }</td>
                                    <td style={{'width':'100px'}}>{ asset.foglio ? asset.foglio : '-' }</td>
                                    <td style={{'width':'100px'}}>{ asset.mappale ? asset.mappale :'-' }</td>
                                    <td>{ asset.subalterno ? asset.subalterno : '-' }</td>
                                    <td>{ asset.registry_category ? asset.registry_category.descrizione : '-' }</td>
                                  </tr>
                                ) : null
                            ) : '' }
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                    </>
                  : '' }
                  { evaluation && evaluation.num_asset.T > 0 ?
                    <>
                      <label><b>Terreni</b></label>
                      <Row>
                        <Col className='tab_wrapper'>
                          <Table responsive>
                          <thead>
                            <tr>
                              {/* <th style={{'width':'60px'}}>Tipo</th> */}
                              <th style={{'width':'350px'}}>Comune catastale</th>
                              <th style={{'width':'100px'}}>Sezione</th>
                              <th style={{'width':'100px'}}>Foglio</th>
                              <th style={{'width':'100px'}}>Particella</th>
                              <th></th>
                              {/* <th>Porzione</th> */}
                              {/* <th>Qualità</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            { evaluation && evaluation.assets && evaluation.assets.length ? evaluation.assets.map((asset) =>
                                asset.parcel_type.sigla == 'T' ? (
                                  <tr key={ asset.id }>
                                    {/* <td>{ asset.parcel_type ? asset.parcel_type.sigla : '-' }</td> */}
                                    <td style={{'width':'350px'}}>{ asset.comune_catastale }</td>
                                    <td style={{'width':'100px'}}>{ asset.sezione ? asset.sezione : '-' }</td>
                                    <td style={{'width':'100px'}}>{ asset.foglio ? asset.foglio : '-' }</td>
                                    <td style={{'width':'100px'}}>{ asset.mappale ? asset.mappale : '-' }</td>
                                    <td></td>
                                    {/* <td>{ asset.subalterno }</td> */}
                                    {/* <td>{ asset.registry_category ? asset.registry_category.descrizione : '-' }</td> */}
                                  </tr>
                                ) : null
                            ) : '' }
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                    </>
                  : '' }
                </div>
              </fieldset>
              {/* STEP 2 - RILEVAMENTI FOTOGRAFICI */}
              <fieldset className={step == 2 ? 'wizard_step_content reg visible' : 'wizard_step_content reg'}>
                <Row>
                  <Col>
                    <h2>Validazione rilevamenti fotografici</h2>
                  </Col>
                </Row>
                <div className='inner_wrapper'>
                  <Row>
                    <Col lg={7}>
                      <ul id="foto_cats_list">
                        { inspectionFotoCats && inspectionFotoCats.length ? inspectionFotoCats.map((c) => 
                          <li key={c.id}>
                            <div className={'foto_cat_state '+c.stato}></div>
                            <b>{ c.nome }</b> { c.min ? '(min '+c.min+' foto)' : '' }
                          </li>
                        ) : '' }
                      </ul>
                    </Col>
                    <Col lg={5}>
                      <Row>
                        <Col>
                          <label>Amministratore</label>
                          <p>{ evaluation && evaluation.inspection ? evaluation.inspection.amministratore_nome : '-' }</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <label>Telefono</label>
                          <p>{ evaluation && evaluation.inspection ? evaluation.inspection.amministratore_telefono : '-' }</p>
                        </Col>
                        <Col>
                          <label>E-mail</label>
                          <p>{ evaluation && evaluation.inspection ? evaluation.inspection.amministratore_email : '-' }</p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
                <Row>
                  <Col>
                    <label className='step_label'>Elenco file caricati con aggiunte</label>
                  </Col>
                </Row>
                <Row>
                  <Col className='tab_wrapper'>
                    { inspectionFotoFiles && inspectionFotoFiles.length ? inspectionFotoFiles.map((f) => 
                      <div className={ f.discarded_at ? 'fotoThumbWrapper discarded' : f.user && f.user.role && f.user.role.slug == 'qual-cont' ? 'fotoThumbWrapper added' : 'fotoThumbWrapper' }>
                        <p>
                          <img src={process.env.PUBLIC_URL+'/gfx/SPF_AssetsIcone-32.png'} style={{'height':'16px'}} />
                          <span className='filename'>{ f.nome }</span>
                        </p>
                        <div className='fotoThumb' style={{'backgroundImage':"url('"+f.link+"')"}}></div>
                        <div className='bottom_bar'>
                          <span style={{'fontSize':'12px','display':'inline-block','fontWeight':'600','marginRight':'10px'}}>{ f.inspection_image_category.descrizione.substr(0, 24)+(f.inspection_image_category.descrizione.length > 24 ? '...' : '') }</span>
                          <a href="#" style={{'float':'right'}} onClick={() => { window.open(f.link); }}><DynamicIcon iconName="FaDownload" /></a>                                     
                        </div>
                      </div>
                    ) : '' }
                  </Col>
                </Row>
              </fieldset>
              {/* STEP 3 - SCHEDA CATASTALE E DIFFORMITÀ */}
              <fieldset className={step == 3 ? 'wizard_step_content reg visible' : 'wizard_step_content reg'}>
                <Row>
                  <Col>
                    <h2>Validazione scheda catastale e difformità</h2>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label className='step_label'>Elenco file caricati con aggiunte</label>
                  </Col>
                </Row>
                <Row>
                  <Col className='tab_wrapper'>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>Nome</th>
                          {/* <th>Peso</th>
                          <th>Data caricamento</th> */}
                          <th>Tripletta catastale</th>
                          <th style={{'width':'120px'}}>Azioni</th>
                        </tr>
                      </thead>
                      <tbody>
                        { inspectionDiffFiles && inspectionDiffFiles.length ? inspectionDiffFiles.map((f) => 
                          <tr className={ f.discarded_at ? 'discarded' : f.user && f.user.role && f.user.role.slug == 'qual-cont' ? 'added' : '' }>
                            <td>{ f.nome }</td>
                            {/* <td>{ f.peso }</td>
                            <td>{ f.data_caricamento }</td> */}
                            <td>{ f.asset ? f.asset.foglio+' '+f.asset.mappale+' '+f.asset.subalterno : '-' }</td>
                            <td className="actions">
                              <Button title="Scarica file" onClick={() => { window.open(f.link); }}><DynamicIcon iconName="FaDownload" /></Button>
                            </td>
                          </tr>
                        ) : '' }
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <Row style={{'marginTop':'20px'}}>
                  <Col>
                    <label className='step_label'>Riscontro difformità</label>
                  </Col>
                </Row>
                <div className='inner_wrapper'>
                  { evaluation && evaluation.num_asset.F > 0 ?
                  <>
                    <label><b>Fabbricati</b></label>
                    <Row>
                      <Col className='tab_wrapper'>
                        { evaluation && evaluation.assets && evaluation.assets.length ? 
                          <Table responsive>
                            <thead>
                              <tr>
                                {/* <th style={{'width':'50px'}}>Tipo</th> */}
                                <th style={{'width':'350px'}}>Comune catastale</th>
                                <th style={{'width':'90px'}}>Sezione</th>
                                <th style={{'width':'100px'}}>Foglio</th>
                                <th style={{'width':'100px'}}>Particella</th>
                                <th style={{'width':'60px'}}>Sub</th>
                                <th style={{'width':'60px'}}>Cat</th>
                                <th style={{'width':'100px'}}>Difformità</th>
                                <th style={{'width':'140px'}}>Tipo</th>
                                <th style={{'width':'120px'}}>Descrizione risoluzione</th>
                              </tr>
                            </thead>
                            <tbody>
                              { evaluation.assets.map((ass) => 
                                ass.parcel_type.sigla == 'F' ? (
                                  <tr>
                                    {/* <td>{ ass.parcel_type ? ass.parcel_type.sigla : '-' }</td> */}
                                    <td style={{'width':'350px'}}>{ ass.comune_catastale ? ass.comune_catastale : '-' }</td>
                                    <td style={{'width':'90px'}}>{ ass.sezione ? ass.sezione : '-' }</td>
                                    <td style={{'width':'100px'}}>{ ass.foglio ? ass.foglio : '-' }</td>
                                    <td style={{'width':'100px'}}>{ ass.mappale ? ass.mappale :'-' }</td>
                                    <td style={{'width':'60px'}}>{ ass.subalterno ? ass.subalterno : '-' }</td>
                                    <td style={{'width':'60px'}}>{ ass.registry_category ? ass.registry_category.descrizione : '-' }</td>
                                    <td style={{'width':'100px'}}>{ ass.difformita == '1' ? 'Sì' : (ass.difformita == '0' ? 'No' : '-') }</td>
                                    <td style={{'width':'140px'}}>{ ass.inspection_difference ? ass.inspection_difference.descrizione : '-' }</td>
                                    <td className="actions">
                                      <Button title="Vedi risoluzione" onClick={() => { handleResolution(ass.id) }} className={ !ass.difformita || ass.difformita.toString() !== '1' ? 'disabled' : '' }><DynamicIcon iconName="FaEye" /></Button>
                                    </td>
                                  </tr>
                                ) : null
                              ) }
                            </tbody>
                          </Table>
                        : '' }
                      </Col>
                    </Row>
                  </>
                  : '' }
                  { evaluation && evaluation.num_asset.T > 0 ?
                  <>
                    <label><b>Terreni</b></label>
                    <Row>
                      <Col className='tab_wrapper'>
                        { evaluation && evaluation.assets && evaluation.assets.length ? 
                          <Table responsive>
                            <thead>
                              <tr>
                                {/* <th style={{'width':'50px'}}>Tipo</th> */}
                                <th style={{'width':'350px'}}>Comune catastale</th>
                                <th style={{'width':'100px'}}>Sezione</th>
                                <th style={{'width':'100px'}}>Foglio</th>
                                <th style={{'width':'100px'}}>Particella</th>
                                <th style={{'width':'120px'}}></th>
                                <th style={{'width':'100px'}}>Difformità</th>
                                <th style={{'width':'140px'}}>Tipo</th>
                                <th style={{'width':'120px'}}>Descrizione risoluzione</th>
                              </tr>
                            </thead>
                            <tbody>
                              { evaluation.assets.map((ass) => 
                                ass.parcel_type.sigla == 'T' ? (
                                  <tr>
                                    {/* <td>{ ass.parcel_type ? ass.parcel_type.sigla : '-' }</td> */}
                                    <td style={{'width':'350px'}}>{ ass.comune_catastale }</td>
                                    <td style={{'width':'100px'}}>{ ass.sezione ? ass.sezione : '-' }</td>
                                    <td style={{'width':'100px'}}>{ ass.foglio ? ass.foglio : '-' }</td>
                                    <td style={{'width':'100px'}}>{ ass.mappale ? ass.mappale : '-' }</td>
                                    <td style={{'width':'120px'}}></td>
                                    <td style={{'width':'100px'}}>{ ass.difformita == '1' ? 'Sì' : (ass.difformita == '0' ? 'No' : '-') }</td>
                                    <td style={{'width':'140px'}}>{ ass.inspection_difference ? ass.inspection_difference.descrizione : '-' }</td>
                                    <td className="actions">
                                      <Button title="Vedi risoluzione" onClick={() => { handleResolution(ass.id) }} className={ !ass.difformita || ass.difformita.toString() !== '1' ? 'disabled' : '' }><DynamicIcon iconName="FaEye" /></Button>
                                    </td>
                                  </tr>
                                ) : null
                              ) }
                            </tbody>
                          </Table>
                        : '' }
                      </Col>
                    </Row>
                  </>
                  : '' }
                </div>
              </fieldset>
              {/* STEP 4 - QUESTIONARIO FINALE E RIEPILOGO */}
              <fieldset className={step == 4 ? 'wizard_step_content reg visible' : 'wizard_step_content reg'}>
                <Row><Col><label className='step_label'>VALIDAZIONE QUESTIONARIO FINALE E NOTE</label></Col></Row>
                <div className='inner_wrapper'>
                  <Row>
                    <Col>
                      <Row style={{'marginBottom':'10px'}}>
                        <Col>
                          <label>Hai avuto accesso a tutti i beni indicati come quelli da periziare?</label>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          { evaluation && evaluation.inspection && JSON.parse(evaluation.inspection.questionario)[0] == '1' ? 'Sì' : 'No' }
                        </Col>
                      </Row>
                      <Row style={{'marginBottom':'10px','marginTop':'25px'}}>
                        <Col>
                          <label>Hai potuto fare le foto a tutti gli ambienti?</label>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          { evaluation && evaluation.inspection && JSON.parse(evaluation.inspection.questionario)[1] == '1' ? 'Sì' : 'No' }
                        </Col>
                      </Row>
                      <Row style={{'marginBottom':'10px','marginTop':'25px'}}>
                        <Col>
                          <label>Hai potuto prendere le misure reali?</label>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          { evaluation && evaluation.inspection && JSON.parse(evaluation.inspection.questionario)[2] == '1' ? 'Sì' : 'No' }
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <label>Note del tecnico</label>
                      <p>{ evaluation && evaluation.inspection ? evaluation.inspection.note : '-' }</p>
                      <label>Note della qualità - controllo</label>
                      <p>{ evaluation && evaluation.inspection ? evaluation.inspection.note_qualita_1 : '-' }</p>
                    </Col>
                  </Row>
                </div>
                <Row style={{'marginTop':'40px'}}>
                  <Col>
                    <label className='step_label'>Aggiungi nota (facoltativo)</label>
                    <textarea placeholder="Inserisci qui la tua nota" {...register("inspection[note_qualita_2]")}></textarea>
                  </Col>
                </Row>
              </fieldset>
            </form>
          </Col>
          <Col className='sidebar' style={{'paddingTop':'60px'}}>
            { step == 0 ?
              <>
                <Button className='sidebar_action' onClick={() => { goToStep(999) }}>Approva pratica</Button>  
                <Button className='sidebar_action' onClick={() => { goToStep(1) }}>Approfondisci</Button>  
              </>
            : '' }
            <Button className='sidebar_action suspend' onClick={() => { handleSuspension(state.evaluation_id) }}>Sospendi pratica</Button>

            { step == 0 ?
              <>
                <h2>Storico stati</h2>
                <div id="wizard_bar" className="vert">
                  { evaluation && evaluation.storico_stati ? Object.entries(evaluation.storico_stati).map(([key, stato]) => (
                    stato.descrizione ? (
                      <div className={stato.data ? 'step active' : 'step'}>
                        { stato.descrizione } { stato.data ? 'il' : '' }
                        <span className='date'>{ stato.data ? stato.data.substr(0, 10) : '' }</span>
                      </div>
                    ) : null
                  )) : <p>Non sono presenti stati per questa pratica</p> } 
                </div>
              </>
            :
              <>
                <h2 style={{'marginTop':'40px'}}>Fasi validazione</h2>
                <div id="wizard_bar" className="vert">
                  <div className={step >= 1 ? 'step active' : 'step'}>Coordinate e dati catastali</div>
                  <div className={step >= 2 ? 'step active' : 'step'}>Rilevamenti fotografici</div>
                  <div className={step >= 3 ? 'step active' : 'step'}>Scheda catastale e difformità</div>
                  <div className={step >= 4 ? 'step active' : 'step'}>Questionario finale e riepilogo</div>
                </div>
              </>
            }
          </Col>
        </Row>
        <Row className="action_bar bottom">
          <Col>
            { step != 0 && step > 1 ?
              <Button onClick={() => goToStep(step-1)}>
                <DynamicIcon iconName='IoIosArrowBack' /> Indietro
              </Button>
            : '' }
            { step != 0 && step < 4 ?
              <Button className='inverse' onClick={() => goToStep(step+1)}>
                <DynamicIcon iconName='IoIosArrowForward' /> Continua
              </Button>
            : '' }
            { step != 0 && step == 4 ?
              <Button className='inverse' onClick={() => goToStep(999)}>
                <DynamicIcon iconName='IoIosArrowForward' /> Fine validazione
              </Button>
            : '' }
          </Col>
        </Row>
        <br /><hr />
        <Row className="action_bar bottom">
          <Col>
            <Button onClick={() => { navigate('/evaluations', { state: { page: (state && state.page ? state.page : 1), query: (state && state.query ? state.query : null) }}); }}>
              <DynamicIcon iconName='IoIosArrowBack' /> Torna alle pratiche
            </Button> 
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default EvaluationsVal;