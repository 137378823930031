import React, { useContext, useEffect, useState } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation } from 'react-router-dom';
// AXIOS
import axios from 'axios';
// REACT HOOK FORM
import { useForm } from "react-hook-form";
// REACT SELECT
import Select from 'react-select';
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
// REACT SPINNER
import { Watch } from 'react-loader-spinner';
// REACT SWEETALERT
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// DYNAMIC ICON
import DynamicIcon from '../partials/DynamicIcon';

function ProjectsAdd() {
  // AUTH, STATE, NAVIGATION
  const { authobj } = useContext(AuthContext);
  const { state } = useLocation();
  let navigate = useNavigate();
  // FORM
  const { register, control, handleSubmit, trigger, getValues, setValue, reset, formState: { errors } } = useForm();
  // SWEETALERT
  const MySwal = withReactContent(Swal);
  // DATA
  const [project, setProject] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [selectCustomers, setSelectCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [evaluationTypes, setEvaluationTypes] = useState([]);
  // SPINNER
  const [wait, setWait] = useState(false);
  const [waitPercent, setWaitPercent] = useState(null);

  useEffect(() => {
    setWait(true);

    // Carico i clienti
    axios.post(window.SERVER_URL+'customers/list', { params: { 'light': true } }, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      setCustomers(res.data.customers);
      let select_options = [];
      res.data.customers.map((c) => {
        select_options.push({ value: c.id, label: c.etichetta });
      });
      setSelectCustomers(select_options);

      // Carico le tipologie di pratiche
      axios.post(window.SERVER_URL+'evaluation_types/list', { }, { headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token
      }}).then(res => {
        setEvaluationTypes(res.data.evaluation_types);
    
        if (state && state.project_id) {
          axios.post(window.SERVER_URL+'projects/get', { id: state.project_id }, { headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer '+authobj.token
          }}).then(res => {
            console.log(res.data.project);
            setProject(res.data.project);

            // Setto i campi della form
            let fields = {};
            for (const [key, val] of Object.entries(res.data.project))
              if (typeof val !== 'object')
                  fields[`project[${key}]`] = val;
            console.log(fields);
            reset(fields);

            // Setto le select
            if (res.data.project.user_id)
              setSelectedCustomer({ value: res.data.project.user_id, label: res.data.project.user.etichetta });

            // Setto il listno
            if (res.data && res.data.project.prices) {
              res.data.project.prices.forEach((price) => {
                setValue("prezzi[_"+price.evaluation_type_id+"][costo_cliente]", price.prezzo_cliente);
                setValue("prezzi[_"+price.evaluation_type_id+"][costo_tecnico]", price.prezzo_tecnico);
              });
            }

            setWait(false);
          });
        } else {
          setWait(false);
        }
      });
    });
  }, [reset]);

  const handleChangeCustomer = (e) => {
    setWait(true);
    
    setSelectedCustomer(e);

    axios.post(window.SERVER_URL+'customers/get', { id: e.value }, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      console.log(res.data);
      if (res.data.user && res.data.user.prices && res.data.user.prices.length) {
        res.data.user.prices.forEach((price) => {
          setValue("prezzi[_"+price.evaluation_type_id+"][costo_cliente]", price.prezzo_cliente);
          setValue("prezzi[_"+price.evaluation_type_id+"][costo_tecnico]", price.prezzo_tecnico);
        });
        setWait(false);
        Swal.fire({
          icon: 'success',
          title: 'Listino importato',
          text: "Il listino prezzi del ciente è stato importato"
        });
      } else {
        setWait(false);
        Swal.fire({
          icon: 'warning',
          title: 'Attenzione',
          text: "Questo cliente non ha un listino prezzi da importare"
        });
      }
    });
  }
  
  // Submit della form
  const onSubmit = (form_data) => {
    console.log(form_data);   

    const formData = new FormData();
    if (state && state.project_id)
      formData.append('project[id]', state.project_id);
    for (const [key, value] of Object.entries(form_data.project))
      formData.append('project['+key+']', value);
    for (const [key, value] of Object.entries(form_data.prezzi)) {
      formData.append('prezzi['+key+'][costo_cliente]', value.costo_cliente);
      formData.append('prezzi['+key+'][costo_tecnico]', value.costo_tecnico);
    }
    if (form_data.allegato)
      formData.append('allegato', form_data.allegato[0]);
    
    axios.post(window.SERVER_URL+'projects/save', formData, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token,
        'Content-Type': 'multipart/form-data'
      }
    }).then(res => {
      if (res.data.success) {
        Swal.fire({
          icon: 'success',
          title: 'Progetto salvato',
          text: 'Progetto salvato correttamente'
        }).then((result) => {
          if (result.isConfirmed) {
            navigate('/projects');
          }
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Errore',
          text: "Non è stato possibile salvare il progetto"
        });
      }
    }).catch(function(error) {
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'Errore',
        text: "Non è stato possibile salvare il progetto"
      });
    });
  }

  return (
    <>
      { wait ? 
        <div id="wait">
          { waitPercent !== null ?
            <div className="percent">{waitPercent}%</div>
          : '' }
          <Watch height="80" width="80" radius="48" color="#44B2EB" ariaLabel="watch-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />
        </div>
      : '' }
      <Container className="content">
        <Row className="page_title">
          <Col>
            <h1>Aggiungi o modifica progetto</h1>
          </Col>
        </Row>
        <Row>
          <Col className="custom_form_wrapper">
            <form className="custom_form" onSubmit={handleSubmit(onSubmit)}>
              <fieldset style={{'background':'#f8f8f8','padding':'5px 20px 20px'}}>
                <label className="fieldset_label"><DynamicIcon iconName="FaUser" size="20" spaced /> CLIENTE</label>
                <Row>
                  <Col>
                    <label style={{'marginBottom':'18px'}}>Cliente *</label>
                    <Select value={selectedCustomer} required={true} isDisabled={false} isLoading={false} isClearable={true} isRtl={false} isSearchable={true} name="project[user_id]" options={selectCustomers} onChange={(e) => handleChangeCustomer(e)} />
                  </Col>
                </Row>
              </fieldset>

              <fieldset style={{'background':'#f8f8f8','padding':'5px 20px 20px','marginTop':'20px'}}>
                <label className="fieldset_label"><DynamicIcon iconName="GrTextAlignLeft" size="20" spaced /> INFORMAZIONI</label>
                <Row>
                  <Col>
                    <label>Codice</label>
                    <input key={"codice"} type="text" placeholder="Codice" {...register("project[codice]", { required: true })} />
                  </Col>
                  <Col>
                    <label>Data inizio</label>
                    <input key={"data_inizio"} type="date" {...register("project[data_inizio]", { required: true })} />
                  </Col>
                  <Col>
                    <label>Data fine</label>
                    <input key={"data_fine"} type="date" {...register("project[data_fine]", { required: false })} />
                  </Col>
                </Row>
              </fieldset>
              <fieldset style={{'background':'#f8f8f8','padding':'5px 20px 20px','marginTop':'20px'}}>
                <label className="fieldset_label"><DynamicIcon iconName="FaClipboardList" size="20" spaced /> LISTINO PREZZI</label>
                <Row>
                  <Col>
                      <Table responsive style={{'marginTop':'20px'}}>
                        <thead>
                          <tr>
                            <th>Tipo perizia</th>
                            <th>Costo progetto</th>
                            <th>Costo tecnico</th>
                          </tr>
                        </thead>
                        <tbody>
                          { evaluationTypes && evaluationTypes.length ? evaluationTypes.map((type, i) =>
                            <tr key={i}>
                              <td>{ type.descrizione }</td>
                              <td>
                                <div className='field_listino_wrapper'>
                                  <input className='field_listino' key={"prezzi[_"+type.id+"][costo_cliente]"} type="number" step="0.10" placeholder="" {...register("prezzi[_"+type.id+"][costo_cliente]")} />
                                </div>
                              </td>
                              <td>
                                <div className='field_listino_wrapper'>
                                  <input className='field_listino' key={"prezzi[_"+type.id+"][costo_tecnico]"} type="number" step="0.10" placeholder="" {...register("prezzi[_"+type.id+"][costo_tecnico]")} />
                                </div>
                              </td>
                            </tr>
                          ) : '' }
                        </tbody>
                      </Table>
                  </Col>
                </Row>
              </fieldset>
              <fieldset style={{'background':'#f8f8f8','padding':'5px 20px 20px','marginTop':'20px'}}>
                <label className="fieldset_label"><DynamicIcon iconName="GrAttachment" size="20" spaced /> ALLEGATO</label>
                { project && project.files && project.files.length ? project.files.map((f) => {
                  return <Row style={{'marginTop':'20px'}}>
                            <Col>
                                <p style={{'display':'inline-block'}}>
                                  <img src={`${process.env.PUBLIC_URL}/gfx/Iconly-Light-Paper.png`} style={{'display':'inline-block','marginRight':'10px'}} />
                                  File attuale: <a target="_blank" href={ f.link }>
                                                  { f.nome }
                                                </a>
                                                &nbsp;
                                                ({ f.peso })
                                                
                                </p>
                            </Col>
                          </Row>
                }) : <p style={{'marginTop':'20px'}}>Se vuoi, puoi caricare un allegato</p> }
                <Row style={{'paddingBottom':'20px','marginBottom':'20px','borderBottom':'1px solid #ddd'}}>
                  <Col>
                    <label>Carica allegato</label>
                    <input type="file" name={'allegato'} {...register('allegato', { required: false })} />
                  </Col>
                </Row>
              </fieldset>

              <Button type="submit" variant="primary" className="waste_btn">Salva</Button>
             
            </form>
          </Col>
        </Row>
        <Row className="action_bar bottom">
          <Col>
            <Button onClick={() => navigate('/projects', { state: { page: (state && state.page ? state.page : 1), query: (state && state.query ? state.query : null) } })}><DynamicIcon iconName='IoIosArrowBack' /> Torna ai progetti</Button>
          </Col>
        </Row>
      </Container>
    </>
  );

}

export default ProjectsAdd;