import React from 'react';
// REACT ROUTER
import * as ReactDOMClient from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
// AUTH
import { AuthProvider, RequireAuth } from './components/contexts/AuthContext';
// LAYOUT
import Main from './components/main.js';
// FRONT
import Login from './components/pages/login';
import Dashboard from './components/pages/dashboard';
import DashboardLate from './components/pages/dashboard_late';
import Users from './components/pages/users';
import UsersAdd from './components/pages/users_add';
import Roles from './components/pages/roles';
import RolesAdd from './components/pages/roles_add';
import Permissions from './components/pages/permissions';
import Evaluations from './components/pages/evaluations';
import EvaluationsAdd from './components/pages/evaluations_add';
import EvaluationsAss from './components/pages/evaluations_ass';
import EvaluationsReg from './components/pages/evaluations_reg';
import EvaluationsDet from './components/pages/evaluations_det';
import EvaluationsCon from './components/pages/evaluations_con';
import EvaluationsVal from './components/pages/evaluations_val';
import ImportEvaluations from './components/pages/import_evaluations';
import Customers from './components/pages/customers';
import CustomersAdd from './components/pages/customers_add';
import Technicians from './components/pages/technicians';
import TechniciansAdd from './components/pages/technicians_add';
import Alerts from './components/pages/alerts';
import ExportInvoiceable from './components/pages/export_invoiceable';
import Projects from './components/pages/projects';
import ProjectsAdd from './components/pages/projects_add';

const container = document.getElementById('root');
const root = ReactDOMClient.createRoot(container);

root.render(
    <AuthProvider>
        <BrowserRouter>
            <Routes>
                <Route element={<Main />}>
                    {/* FRONT */}
                    <Route path="/" element={<Login  />} />
                    <Route path="/dashboard" element={<RequireAuth><Dashboard /></RequireAuth>} />
                    <Route path="/dashboard/late" element={<RequireAuth><DashboardLate /></RequireAuth>} />
                    <Route path="/users" element={<RequireAuth><Users /></RequireAuth>} />
                    <Route path="/users/add" element={<RequireAuth><UsersAdd /></RequireAuth>} />
                    <Route path="/roles" element={<RequireAuth><Roles /></RequireAuth>} />
                    <Route path="/roles/add" element={<RequireAuth><RolesAdd /></RequireAuth>} />
                    <Route path="/permissions" element={<RequireAuth><Permissions /></RequireAuth>} />
                    <Route path="/evaluations" element={<RequireAuth><Evaluations /></RequireAuth>} />
                    <Route path="/evaluations/add" element={<RequireAuth><EvaluationsAdd /></RequireAuth>} />
                    <Route path="/evaluations/ass" element={<RequireAuth><EvaluationsAss /></RequireAuth>} />
                    <Route path="/evaluations/reg" element={<RequireAuth><EvaluationsReg /></RequireAuth>} />
                    <Route path="/evaluations/det" element={<RequireAuth><EvaluationsDet /></RequireAuth>} />
                    <Route path="/evaluations/con" element={<RequireAuth><EvaluationsCon /></RequireAuth>} />
                    <Route path="/evaluations/val" element={<RequireAuth><EvaluationsVal /></RequireAuth>} />
                    <Route path="/import/evaluations" element={<RequireAuth><ImportEvaluations /></RequireAuth>} />
                    <Route path="/customers" element={<RequireAuth><Customers /></RequireAuth>} />
                    <Route path="/customers/add" element={<RequireAuth><CustomersAdd /></RequireAuth>} />
                    <Route path="/technicians" element={<RequireAuth><Technicians /></RequireAuth>} />
                    <Route path="/technicians/add" element={<RequireAuth><TechniciansAdd /></RequireAuth>} />
                    <Route path="/alerts" element={<RequireAuth><Alerts /></RequireAuth>} />
                    <Route path="/export/invoiceable" element={<RequireAuth><ExportInvoiceable /></RequireAuth>} />
                    <Route path="/projects" element={<RequireAuth><Projects /></RequireAuth>} />
                    <Route path="/projects/add" element={<RequireAuth><ProjectsAdd /></RequireAuth>} />
                </Route>
            </Routes>
        </BrowserRouter>
    </AuthProvider>
);